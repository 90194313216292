<template>
  <div id="posts">
    <div class="title">Makaleler</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Blog</el-breadcrumb-item>
        <el-breadcrumb-item>Makaleler</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="buttons-group">
        <el-button class="add" @click="setItem(null)"><i class="icon-plus"></i></el-button>
        <div v-if="multipleSelection.length > 0">{{ multipleSelection.length }} adet veri seçildi</div>
        <el-button class="delete" @click="deleteSelected()" v-if="multipleSelection.length > 0"><i class="icon-delete"></i></el-button>
        <!-- <el-button class="print"><i class="icon-print"></i>export</el-button>
        <el-button class="import"><i class="icon-import"></i>import</el-button> -->
      </div>
    </div>
    <div class="all-data">
      <div class="list-table">
        <div class="search-status">
          <div class="status">
            <div class="show">Göster</div>
            <el-select v-model="filter.PageSize" placeholder="Seçiniz...">
              <el-option v-for="(item, index) in pagesize" :key="index" :label="item" :value="item"> </el-option>
            </el-select>
          </div>
          <div class="search">
            <el-input placeholder="Kayıt Ara" v-model="filter.SearchTerm" clearable>
              <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
            </el-input>
          </div>
        </div>
        <el-table ref="multipleTable" :data="postList" @selection-change="handleSelectChange">
          <el-table-column sortable type="selection" align="center" style="text-center"> </el-table-column>
          <el-table-column sortable prop="articleimage" width="155" label="MAKALE RESMİ" stripe>
            <template slot-scope="scope">
              <img :src="scope.row.Image" height="80" alt="" />
            </template>
          </el-table-column>
          <el-table-column sortable prop="Title" label="MAKALE ADI" stripe> </el-table-column>

          <!-- <el-table-column sortable prop="Summary" label="MAKALE ÖZETİ" stripe> </el-table-column> -->

          <el-table-column sortable label="KATEGORİ" width="270" stripe>
            <template slot-scope="scope">
              <div v-if="listCategories.length > 0">
                {{ listCategories.find((x) => x.ID == scope.row.CategoryId).Title }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="LanguageCode" label="DİL" width="80"> </el-table-column>
          <el-table-column sortable label="DURUM" width="100" stripe>
            <template slot-scope="scope">
              <el-switch active-color="#234A98" v-model="scope.row.IsActive" @change="changeStatus(scope.row.ID)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column sortable prop="PublishedDate" width="200" label="YAYINLANMA TARİHİ" stripe>
            <template slot-scope="scope">
              {{ $moment(scope.row.PublishedDate).format("DD.MM.YYYY") }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="" width="90">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link actions-menu">
                  <i class="icon-dots"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span style="display: block" @click="setItem(scope.row)">Detay</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided><span class="tex-danger" style="display: block" @click="deleteFile(scope.row.ID)">Sil</span></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>

          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">Kayıt bulunamadı.</div>
            </div>
          </template>
        </el-table>
        <div class="pagination">
          <div class="info">{{ filter.PageSize * filter.Page - filter.PageSize }}-{{ filter.PageSize * filter.Page > result.TotalCount ? result.TotalCount : filter.PageSize * filter.Page }} {{ $localization('web-pagination-info-text') }} {{ result.TotalCount }}</div>
          <el-pagination background :current-page.sync="filter.Page" :page-size="filter.PageSize" layout="prev, pager, next" :total="result.TotalCount"></el-pagination>
        </div>
      </div>

      <el-dialog :visible.sync="addUpdateDialog" width="30%" :close-on-click-modal="false" top="2vh">
        <div class="title">Makale Ekle/Düzenle</div>

        <div class="close" @click="addUpdateDialog = false">
          <i class="icon-close"></i>
        </div>
        <div class="body">
          <label>Resim</label>

          <img :src="form.Image" width="100%" v-if="form.Image != null" />
          <div class="input-container">
            <el-upload
              class="el-up"
              :action="$client.defaults.baseURL + 'Account/UploadFile?type=3'"
              :headers="{
                Authorization: $client.defaults.headers.common['Authorization'],
              }"
              :show-file-list="false"
              multiple
              :on-success="fileSuccess">
              <el-button size="small" style="justify-self: flex-start" type="primary">
                <i class="icon-plus"></i>
                Resim Ekle</el-button
              >
            </el-upload>
            <span class="error">{{ $errorMessage("Image", validation) }}</span>
          </div>
          <div class="input-container">
            <label>Kapak Resmi</label>
            <img :src="form.Cover" width="100%" v-if="form.Cover != null" />
          </div>

          <div class="input-container">
            <el-upload
              class="el-up"
              :action="$client.defaults.baseURL + 'Account/UploadFile?type=3'"
              :headers="{
                Authorization: $client.defaults.headers.common['Authorization'],
              }"
              :show-file-list="false"
              multiple
              :on-success="fileSuccess2">
              <el-button size="small" style="justify-self: flex-start; border: none" type="primary">
                <i class="icon-plus"></i>
                Resim Ekle</el-button
              >
            </el-upload>
            <span class="error">{{ $errorMessage("Cover", validation) }}</span>
          </div>

          <div class="input-container">
            <label>Başlık</label>
            <el-input v-model="form.Title"></el-input>
            <span class="error">{{ $errorMessage("Title", validation) }}</span>
          </div>

          <div class="input-container">
            <label>Özet</label>
            <el-input v-model="form.Summary"></el-input>
            <span class="error">{{ $errorMessage("Summary", validation) }}</span>
          </div>
          <div class="input-container" style="display: flex; flex-direction: column; gap: 10px">
            <label>Kategori</label>

            <el-select v-model="form.CategoryId" filterable placeholder="Seçiniz..">
              <el-option v-for="item in listCategories" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
            </el-select>
            <span class="error">{{ $errorMessage("CategoryId", validation) }}</span>
          </div>
          <label>İçerik</label>
          <div class="text-edit">
            <div id="toolbar">
              <select class="ql-size">
                <option value="small"></option>
                <!-- Note a missing, thus falsy value, is used to reset to default -->
                <option selected></option>
                <option value="large"></option>
                <option value="huge"></option>
              </select>
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>

              <select class="ql-align">
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
              </select>
            </div>
            <quill-editor ref="myQuillEditor" :options="editorOption" v-model="form.Details"> </quill-editor>
          </div>
          <span class="error">{{ $errorMessage("Details", validation) }}</span>
          <label>Dil Seçiniz</label>
          <el-select v-model="form.LanguageCode" style="width: 100%">
            <el-option v-for="item in languageList" :key="item.ID" :label="item.Title" :value="item.LanguageCode" style="display: flex; align-items: center">
              <img :src="item.Image" style="height: 20px; width: 30px; margin-right: 10px" />
              {{ item.Title }}
            </el-option>
          </el-select>
          <div class="input-container">
            <label>Yayınlanma Tarihi</label>
            <el-date-picker style="width: 100%" type="date" placeholder="Seçiniz.." v-model="form.PublishedDate"></el-date-picker>
            <span class="error">{{ $errorMessage("PublishedDate", validation) }}</span>
          </div>

          <div class="switch">
            <div class="input-container">
              <label>Durum</label>
              <el-switch active-color="#234A98" v-model="form.IsActive"></el-switch>
            </div>

            <div class="input-container">
              <label>Öne Çıkanlarda Göster</label>
              <el-switch active-color="#234A98" v-model="form.IsFeatured"></el-switch>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="button-group-admin">
            <el-button class="cancelReportAdmin" @click="addUpdateDialog = false">İptal</el-button>
            <el-button class="confirmReportAdmin" type="primary" @click="addUpdateBlog()"><i class="icon-send"></i>Kaydet</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      languageList: [],
      pagesize: [10, 20, 30, 40],
      form: {
        ID: null,
        CategoryId: null,
        Title: null,
        Image: null,
        Summary: null,
        Cover: null,
        IsActive: false,
        IsFeatured: false,
        Details: null,
        PublishedDate: null,
        LanguageCode: null,
      },
      addUpdateDialog: false,
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
        placeholder: "Buraya yazınız..",
      },

      multipleSelection: [],
    };
  },
  async beforeMount() {
    await this.$store.dispatch("getPostList", this.filter);
    await this.getCategories();
    await this.getLanguageList();
    this.form.LanguageCode = this.languageList[0].LanguageCode;
  },
  watch: {
    filter: {
      deep: true,
      handler: async function () {
        await this.$store.dispatch("getPostList", this.filter);
      },
    },
  },
  computed: {
    filter() {
      return this.$store.getters.getPostFilter;
    },
    postList() {
      return this.$store.getters.getPostList;
    },
    validation() {
      return this.$store.getters.getPostValidation;
    },
    result() {
      return this.$store.getters.getPostResult;
    },
    listCategories() {
      return this.$store.getters.getCategoryList;
    },
  },
  methods: {
    async getLanguageList() {
      var res = await this.$client.post("/Localization/SystemLanguages");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.languageList = res.data.Data.Items;
      }
    },
    handleSelectChange(val) {
      this.multipleSelection = val;
    },
    fileSuccess(res) {
      if (res.HasError != undefined && res.HasError == true) this.$message.error(res.Message);
      else this.form.Image = res.Item.Url;
    },
    fileSuccess2(res) {
      if (res.HasError != undefined && res.HasError == true) this.$message.error(res.Message);
      else this.form.Cover = res.Item.Url;
    },

    async getCategories() {
      await this.$store.dispatch("getCategoryList", this.filter);
    },
    async deleteFile(id) {
      await this.$store.dispatch("deleteOnePost", id);
    },

    setItem(item) {
      this.$store.commit("setPostValidation", []);
      if (item != null) {
        this.form.ID = item.ID;
        this.form.Title = item.Title;
        this.form.Image = item.Image;
        this.form.Summary = item.Summary;
        this.form.Cover = item.Cover;
        this.form.IsActive = item.IsActive;
        this.form.IsFeatured = item.IsFeatured;
        this.form.Details = item.Details;
        this.form.PublishedDate = item.PublishedDate;
        this.form.CategoryId = item.CategoryId;
      } else {
        this.form.CategoryId = null;
        this.form.ID = null;
        this.form.Title = null;
        this.form.Image = null;
        this.form.Summary = null;
        this.form.Cover = null;
        this.form.IsActive = false;
        this.form.IsFeatured = false;
        this.form.Details = null;
        this.form.PublishedDate = null;
      }
      this.addUpdateDialog = true;
    },
    async changeStatus(id) {
      await this.$store.dispatch("changeStatusPost", id);
    },

    async addUpdateBlog() {
      await this.$store.dispatch("addOrUpdatePost", this.form);
    },
    async deleteSelected() {
      await this.$store.dispatch("deleteAllPost", this.multipleSelection);
    },
  },
};
</script>

<style lang="less">
#posts {
  .el-button--primary {
    border: none !important;
  }
  padding: 42px 30px;
  .switch {
    margin-top: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .el-switch {
      float: right;
    }
    .el-switch.is-checked {
      .el-switch__core {
        background-color: #234A98;
        border-color: #234A98;
      }
    }
  }
  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;
    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      .add {
        box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);

        background: #234A98;
        i {
          background: white;
        }
      }
      div {
        align-self: center;
      }
      button {
        display: flex;
        align-items: center;

        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: none;
        &.add {
          background-color: #234A98;
          color: white;
          i {
            background-color: white;
          }
        }
        &.delete {
          background-color: #eaedf0;
          color: #44566c;
          i {
            background-color: #44566c;
          }
        }
      }
    }
  }
  .all-data {
    .el-switch.is-checked {
      .el-switch__core {
        background-color: #234A98;
        border-color: #234A98;
      }
    }
    .list-table {
      .status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        .show {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #44566c;
          margin-right: 10px;
        }
        .el-select {
          border-radius: 8px;
          max-width: 145px;
          &:first-child {
            width: 75px;
          }
          &:last-child {
            width: 145px;
          }
          border: none;
          .el-input {
            .el-select__caret {
              background: unset;
            }
            input {
              border-radius: 5px;
              border: none;
              color: #44566c;
              background-color: white;
              font-size: 15px;
              font-weight: 400;
              line-height: 17px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
      .el-table {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;
        &::before {
          height: 0;
        }
      }
      .el-table tr {
        background-color: rgba(255, 255, 255, 0);
      }
      table {
        border-spacing: 0px 12px !important;
        border-collapse: separate;
      }
      table td {
        border: none;
      }
      .el-table .el-table__body-wrapper table tr td:last-child {
        color: #b3c0ce;
      }
      .el-table__header {
        border-spacing: 0px 0px !important;
        border-collapse: separate;
      }
      thead {
        th {
          border: none !important;
          background-color: rgba(255, 255, 255, 0) !important;
          padding-bottom: 0;
          padding-top: 0;
        }
        .cell {
          font-weight: 500;
        }
      }
      tbody {
        filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));
        height: auto;
        .el-table__row {
          &:hover {
            background-color: white !important;
          }
          td {
            height: 60px;
          }
          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
          td:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }
          background-color: white !important;
          border-radius: 10px !important;
        }
        .el-button {
          border: none;
        }

        .el-table-column--selection {
          .cell {
            justify-content: center !important;
          }
        }
        td {
          .cell {
            display: flex;
            align-items: center;
            gap: 10px;
            i {
              height: 40px;
            }
          }
        }
      }
    }
    .el-dialog {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      .el-dialog__header {
        padding: 0;
      }
      padding: 0;
      position: relative;
      .close {
        position: absolute;
        right: 1rem;
        cursor: pointer !important;
        top: 1rem;
        width: 28px;
        height: 28px;
        padding: 3px;
        border-radius: 50%;
        background-color: #eaedf0;
        .icon-close {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #44566c;
      }
      .body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        label {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #8697a8;
        }
        input {
          background: #f8fafb;
          border: none;
        }
        .el-upload {
          width: 100%;
          border-color: #8697a8;
        }
        .text-edit {
          #toolbar {
            padding: 9px;
            border: none;
            background-color: #f8fafb !important;
            border-radius: 5px 5px 0px 0px;
            font-family: "Roboto" !important;
          }

          .ql-snow .ql-stroke {
            stroke: #8697a8;
          }
          .ql-active > .ql-snow .ql-stroke {
            stroke: #8697a8;
          }
          .ql-container {
            min-height: 100px;
            border: none !important;
            background-color: #f8fafb;
            resize: both;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #8697a8;
          }
        }
      }
      .dialog-footer {
        width: 100%;
      }
    }
  }
  .search-status {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
    .search {
      .el-input {
        width: 290px;
        border-radius: 8px;
        input {
          border: none;
          border-radius: 8px;
          background-color: white;
          color: #8697a8;
          font-size: 15px;
          font-weight: 400;
          line-height: 17px;
          box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  .pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info {
      font-size: 15px;
      font-weight: 400;
      color: #8697a8;
    }
    .el-pagination {
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
      background-color: white;
      border-radius: 8px;
      button {
        background-color: white;
        padding: 0;
        margin: 0;
        height: 100% !important;
        &.btn-prev {
          border-radius: 8px 0 0 8px;
          border-right: 1px solid #eaedf0;
        }
        &.btn-next {
          border-radius: 0 8px 8px 0;

          border-left: 1px solid #eaedf0;
        }
      }
      .el-pager {
        margin: 0 10px;
        padding: 5px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        justify-content: space-between;
        li {
          padding: 0;
          margin: 0;
          background-color: white;
          &.active {
            background-color: #234A98;
            border-radius: 8px;
            box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
}
</style>
